html {
  width: 100vw;
  overflow-x: hidden;
  /*scroll-behavior: smooth;*/
}


body {
  padding: 0 !important;
  overflow: auto !important;
  margin: 0;
/*  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;*/
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100px;
  position: absolute;
  width: 100vw
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
  url(./fonts/Montserrat/Montserrat-Regular.otf) format("truetype");
}
