.MuiInputBase-input.Mui-disabled {
  color: black;
  line-height: 27px;
  font-size: 16px;
  background-color: transparent;
}

.MuiFormControl-root.MuiTextField-root {
  background-color: transparent;
}
