#standard-basic {
  font-size: 15px !important;
}

.MuiInput-underline:before {
  border: none !important;
}

/*#standard-basic-label {*/
/*  display: none !important;*/
/*}*/
.MuiButton-root {
  text-transform: none !important;
}

#root > div > div:nth-child(3) > div:nth-child(2) > div > div > div {
  border: none !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}
