#root
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div
  > div:nth-child(1)
  > div
  > div:nth-child(2)
  > div {
  position: initial !important;
  margin-top: 0px;
}
