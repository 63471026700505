#standard-basic {
  font-size: 15px !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}
